import React from 'react';

// components
import HeroSection from '../sections/Home/HeroSection';
import Solutions from '../sections/Home/Solutions';
import Integrate from '../sections/Home/Integrate';
import Experiences from '../sections/Home/Experiences';
import Pricing from '../sections/Home/Pricing';
import VerifyUi from '../sections/Home/VerifyUi';
import Demo from '../sections/Home/Demo';
import MetaTagsProvider from '../components/MetaTagsProvider';
import { ClientSideOnly } from '../components/utilities/ClientSideOnly';
import RootLayout from '../layouts/RootLayout.js';
import ContactUsForm from '../components/reusable/forms/contact/ContactUsForm.jsx';

export default function IndexPage() {
    return (
        <>
            <MetaTagsProvider
                title="Identity Authentication & Background Verification API & SDK"
                description="Authenticate.com is a verification platform that provides Knowledge Based Authentication, Photo ID & Passport Verification, Age, Employment, Education & Criminal Background Checks, MVR & DMV Records, Email, SMS, FEIN & SSN Verification."
                keywords="Age Check API, Age Verification API, Background Verification API, Background Verification SDK, Criminal Background Check API, Criminal Conviction Reporting, Driver license Verification API, Education Verification API, Employment Verification API, ID Verification & Background Check Cloud, Identity Authentication, Identity Verification API, KBA API, Knowledge Based Authentication API, Knowledge Based Authentication Quiz, Knowledge Based Authentication Solutions, License Verification API, Email Verification, Evictions API, Facial Recognition API, Global Watch List Report, Multi-Factor Verifications, National Criminal Check, Passport Authentication, Professional License Verification, SMS Verification, SSN Verification, US Corporation or Business Verification, Verify US Passport Data, Verify US Photo ID Data"
                ogImage="https://cdn.authenticating.com/public/website/logos/authenticate-main-open-graph-image.png"
                ogTitle="Identity Authentication & Background Checks"
                ogDescription="Identity Authentication & Background Checks"
                twitterTitle="Identity Authentication & Background Check API"
                twitterDescription="Identity Authentication & Background Checks"
                path=""
            />
            <RootLayout isHomePage>
                <HeroSection />
                <Solutions />
                <ClientSideOnly>
                    <Integrate />
                </ClientSideOnly>
                <Experiences />
                <VerifyUi />
                <Pricing />
                <Demo />
                <ContactUsForm isCloseBtnNeeded={false} />
            </RootLayout>
        </>
    );
}
