import React from 'react';
import { StyledCtaBtn, StyledFooterText, StyledSectionFooter } from '../style';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <StyledSectionFooter>
            <StyledCtaBtn
                bgColor="#252963"
                to="https://portal.authenticate.com/register"
                target="_blank"
                rel="noreferrer"
            >
                Get Started
            </StyledCtaBtn>
            <StyledFooterText>
                Additional promotional credits are available on top-up. For more info,{' '}
                <Link to="#contact" rel="noreferrer">
                    contact us
                </Link>
                .
            </StyledFooterText>
        </StyledSectionFooter>
    );
};

export default Footer;
